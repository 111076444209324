.values-container {
    width: 100%;
    height: 80vh;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    position: relative;
    background-image: url("../../../../img/bubble-bg.png");
}

.values-item {
    width: clamp(70%,2.5vw,100%);
    margin: auto;
    height: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}

.values-item-item-container {
    width: 30%;
    height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    position: relative;
    font-weight: 100;
    background: rgba( 255, 255, 255, 0.1 );
    backdrop-filter: blur( 13px );
    -webkit-backdrop-filter: blur( 13px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.1 );
    box-shadow: 
        0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
} 

.values-item-item-header {
    width: 80%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.values-item-item-header-text {
    font-size: clamp(1.5rem, 2.5vw, 2rem);
    color: rgb(233, 229, 229);
    font-family: 'Abel', sans-serif;
}

.values-item-item-separator {
    width: 80%;
    height: 5%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.values-item-item-body {
    width: 80%;
    height: 70%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.values-item-item-body-text {
    font-size: clamp(1rem, 5.5vw, 1.5rem); 
    color: rgb(233, 229, 229);
    font-family: 'Abel', sans-serif;
}




/************** SMALL SCREEN *************/

@media screen and (max-width: 800px) {
    .values-container {
        width: 100%;
        height: 180vh;
        background-color: transparent !important;
        display: flex;
        flex-direction: column;
    }
    .values-item {
        width: 100%;
        height: 100%; 
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .values-item-item-container {
        width: 90%;
        height: 30%;
        border: 1px solid #8334cb;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}