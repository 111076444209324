 .footer-item { 
    width: 100%;
    height: 50vh; 

} 



/************** SMALL SCREEN *************/

@media screen and (max-width: 800px) {

}