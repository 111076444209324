.testimonials-container {
    width: 100%;
    height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.testimonials-item {
    width: 100%;
    height: 100%; 
    /* background-color: cadetblue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonials-card {
    /* width: clamp(50%,3.5vw,40%); */
    height: 300px;
    /* border: 1px solid white; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonial-card-body {
    width: 40%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-position-x: center;
    background-position-y: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 5px;
}

.testimonials-card-img {
    background-size: 50%;
    background-position-x: center;
    background-position-y: center;
    position: relative;
}

.swiper_container {
    width: 100%;
    height: 80%;
    padding: 2rem 0;
    position: relative;
}

.testimonial-rating {
    width: 50%;
    margin-top: 2% ;
    margin-bottom: 2% ;
}

.testimonial-text {
    width: 80%;
    font-size: clamp(1.5rem, 3.5vw, 2rem);
    margin-top: 2% ;
    margin-bottom: 2% ;
}

.testimonial-name-container {
    width: 80%;
    margin-top: 2% ;
    margin-bottom: 2% ;
    margin-left: auto;
}

.testimonial-name {
    /* width: 80%; */
    font-size: clamp(0.8rem, 3.5vw, 1.2rem);
    text-align: left;
}
.testimonial-title {
    /* width: 80%; */
    font-size: clamp(0.5rem, 5.5vw, 1rem);
    text-align: left;
}


/************** SMALL SCREEN *************/

@media screen and (max-width: 800px) {
    .testimonials-container {
        width: 100%;
        /* height: 100vh;  */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .testimonials-item {
        width: 100%;
        height: 100%; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .testimonials-card {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .testimonial-card-body {
        width: 90%;
        height: 100%;
        background-position-x: center;
        background-position-y: center;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .testimonial-rating {
        width: 50%;
        margin-top: 2% ;
        margin-bottom: 2% ;
    }

    .testimonial-text {
        width: 80%;
        font-size: clamp(1.5rem, 3.5vw, 2rem);
        margin-top: 2% ;
        margin-bottom: 2% ;
    }

    .testimonial-name {
        width: 80%;
        font-size: clamp(0.8rem, 3.5vw, 1.2rem);
        margin-top: 2% ;
        margin-bottom: 2% ;
    }


    .swiper_container {
        width: 100%;
        height: 80%;
        padding: 2rem 0;
        position: relative;
    }

    
}
