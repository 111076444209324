

.item-title {
    height:15vh;
    font-size: clamp(3rem, 2.5vw, 3.5rem);
    color: rgb(196, 196, 196);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.component-container {
    background-color: black;
}

@media screen and (max-width: 800px) {



}