.description-container {
    width: 100%;
    height: 80vh;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

}


.description-item {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description-item-text-container {
    width: 75%;
    height:85%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid rgb(233, 229, 229);
    position: relative;
    font-weight: 100;
    box-shadow: 
        0 0 10px 3px rgba(255 , 255, 255, 0.4),
        inset 0 0 5px 2px rgba(255 , 255, 255, 0.4),
        
        0 0 5px 3px rgba(248, 28, 229,0.3),
        inset 0 0 5px 3px rgba(248, 28, 229,0.3);
}

.description-item-text-header {
    
    font-size: clamp(2rem, 2.5vw, 2.5rem);
    color: rgb(196, 196, 196);
}

.description-item-text-body {
    font-size: clamp(1.5rem, 2.5vw, 2rem);
    color: rgb(196, 196, 196);
    width: 95%;
}






/************** SMALL SCREEN *************/

@media screen and (max-width: 800px) {

    .description-container {
        width: 100%;
        height: 80vh;
        background-color: transparent !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .description-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .description-item-text-container {
        width: 95%;
        height:90%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: 1px solid rgb(233, 229, 229);
        position: relative;
        font-weight: 100;
        box-shadow: 
            0 0 10px 3px rgba(255 , 255, 255, 0.4),
            inset 0 0 5px 2px rgba(255 , 255, 255, 0.4),
            
            0 0 5px 3px rgba(248, 28, 229,0.3),
            inset 0 0 5px 3px rgba(248, 28, 229,0.3);
    }
}